import React, { useContext, useEffect, useRef, useState } from 'react';
import { enviarFotoPerfil, obterFotoPerfil } from '../../../actions/assistamais/api';
import photoIcon from '../../../assets/img/photo-icon.png';
import { MessageContext } from '../../../contexts/MessageContext';
import { SessionContext } from '../../../contexts/SessionContext';
import './style.scss';

const FotoPerfilEdit = ({ fot, className }) => {

    const inputFileRef = useRef();

    const [foto, setFoto] = useState();
    const [, , showMessageFromResultRequest] = useContext(MessageContext);
    const [session, setSession, logado] = useContext(SessionContext);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            //reader.readAsText(file)
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleChangeImage = async (e) => {

        if (e.target.files[0]) {

            let formData = new FormData();
            formData.append('foto', e.target.files[0]);

            if (showMessageFromResultRequest(enviarFotoPerfil(formData), "Foto atualizada com sucesso")) {
                setFoto(await getBase64(e.target.files[0]));
                document.location.reload(true);

            }
        }


    }

    useEffect(() => {
        setFoto(session?.perfil.foto);
    }, [session])

    return (
        <div className={`foto-perfil-edit ${className}`}>
            <img src={foto} className="foto-peril-edit-img" alt=""></img>
            {/* <input type="file" name="file"
                className="upload-file"
                id="file"
                style={{ display: 'none' }}
                onChange={handleChangeImage}
                encType="multipart/form-data"
                ref={inputFileRef} >

            </input> */}
            {/* <img src={photoIcon} className="camera-icon" alt='Câmera Icon' onClick={() => inputFileRef.current.click()} /> */}
        </div>
    )
};

export default FotoPerfilEdit;