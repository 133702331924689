import { faAngleDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { MessageContext } from '../../../contexts/MessageContext';
import { SessionContext } from '../../../contexts/SessionContext';
import './style.scss';


export default function InputBuscaResgatesMobile({ placeholder, handleChange }) {
    const [valor, setValor] = useState('');

    const handleSubmit = (event) => {
        setValor(event.target.value);
        handleChange(event);
    }

    return (
        <div className="busca-containerMobile">

            <div className="buscaMobile" >
                <div className="input-buscaMobile">
                    <input type="text" name="busca" value={valor} placeholder={placeholder} onChange={(e) => handleSubmit(e)} />
                    <div className="botao lupa"><FontAwesomeIcon icon={faSearch} /></div>
                </div>
            </div>
        </div>

    );
}