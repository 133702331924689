import BotaoArredondado from '../../atoms/BotaoArredondado';
import InputBuscaResgates from '../../atoms/InputBuscaResgates';
import './style.scss';
import InputBuscaResgatesMobile from '../../atoms/InputBuscaResgatesMobile'; 


const FiltroVoucherMobile = ({ placeholder, handleChange, className = ''}) => {

    return (

        <div className={`filtro-voucher-mobile ${className}`}>
            <InputBuscaResgatesMobile className="input-resgate" placeholder={placeholder} handleChange={handleChange} >                
            </InputBuscaResgatesMobile>
            
        </div>

    )
};

export default FiltroVoucherMobile;