import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDetectClickOutside } from 'react-detect-click-outside';
import { getFavoritos, obterClienteResumo, obterExtrato, obterProdutos, obterFiltros, obterSaldo } from '../../actions/assistamais/api';
import CardPopup from '../../componentes/molecules/CardPopup';
import FiltroVoucher from '../../componentes/molecules/FiltroVoucher';
import ListaResgatePontosHorizontal from '../../componentes/organisms/ListaResgatePontosHorizontal';
import Rodape from '../../componentes/organisms/Rodape';
import './style.scss';
import 'react-loading-skeleton/dist/skeleton.css'
import Menu from '../../componentes/organisms/Menu';
import ContaPerfil from '../../componentes/organisms/ContaPerfil';
import ContaSaldo from '../../componentes/organisms/ContaSaldo';
import LabelBordaArredondado from '../../componentes/atoms/LabelBordaArredondado';
import BotaoBordaArredondadoSemTransparenciaPontos from '../../componentes/atoms/BotaoBordaArredondadoSemTranparenciaPontos';
import ExtratoDePontos from '../../componentes/organisms/ExtratoDePontos';
import FiltroVoucherMobile from '../../componentes/molecules/FiltroVoucherMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import BotaoBordaArredondadoSemTransparenciaMobile from '../../componentes/atoms/BotaoBordaArredondadoSemTranparenciaMobile';
import FiltroVoucherPontos from '../../componentes/molecules/FiltroVoucherPontos';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import { SessionContext } from '../../contexts/SessionContext';
import { gravarDados } from '../../utils/Session';




export default function MeusPontos({saldo = 0, className, extratoPontoClick }) {
    
    const [session, setSession] = useContext(SessionContext);

    const [favoritos, setFavoritos] = useState([]);
    const [perfil, setPerfil] = useState([]);
    const [filtros, setFiltros] = useState({categorias: [], pontos: []});
    const [produtos, setProdutos] = useState([]);
    const [extrato, setExtrato] = useState();
    const [filter, setFilter] = useState('');
    const [filterCategory, setFilterCategory] = useState('');
    const [filterOrder, setFilterOrder] = useState('');
    const [filteredCategoria, setFilteredCategoria] = useState('');
    const [showDrop, setShowDrop] = useState(false);

    const extratoDePontosRef = useRef();
    const cardPopup = useRef(null);

    const prodsFiltred = filter == '' && filterOrder == '' && filterCategory == '' ? produtos : filteredProdutos()

    const refDropDownCategory = useDetectClickOutside({ onTriggered: handleCloseDropDown });

    useEffect(() => {
        async function fetchData() {

            //            const { genres } = (await getGenresMovieList()).payload.data;

            getFavoritos().then(result => {
                // TODO criar função para usar em todos

                if (Math.trunc(result.payload.status / 100) !== 2) return;


                let { data } = result.payload;

                setFavoritos(data.map(f => {
                    let pathname = '/filme';
                    if (f.tipo.toLowerCase() === 'tv') {
                        pathname = 'serie';
                    }
                    const linkDetalhe = `${pathname}/?id=${f.tituloId}&type=${f.tipo}`;
                    const { genres, backdrop_path, poster_path, title, overview } = f.data

                    return {
                        ...f,
                        genres,
                        backdrop_path,
                        poster_path,
                        title,
                        overview,
                        linkDetalhe: linkDetalhe
                    }
                }).reverse());

            });

            obterClienteResumo().then(result => {
                setPerfil(result.payload.data);

                setSession({
                    ...session,
                    perfil: {
                        ...session.perfil,
                        pontos: result.payload.data.pontos
                    }
                });
                
            })

            obterProdutos().then(result => {
                setProdutos(result.payload.data.results);
            });

            obterFiltros().then(result => {
                setFiltros({
                    pontos: result.payload.data.pontos, 
                    categorias: result.payload.data.categorias
                });
            });           

            obterExtrato().then(result => {
                setExtrato(result.payload.data);
            });
        }
        fetchData()
    }, []);

    function filteredProdutos() {
      
        let newProds = [];
        
        if (filterCategory != '') {
            newProds = produtos.filter(filtroCategory);

            if (filter != '' && filterOrder == '') {
                newProds = newProds.filter(filtroGlobal);
            } else if (filter != '' && filterOrder != '') {
                newProds = newProds.filter(filtroGlobal);
    
                newProds = newProds.sort(sortOrder);
            } else {
                
                newProds = newProds.sort(sortOrder);
            }
        } else {
            if (filter != '' && filterOrder == '') {
                newProds = produtos.filter(filtroGlobal);
            } else if (filter != '' && filterOrder != '') {
                newProds = produtos.filter(filtroGlobal);
    
                newProds = newProds.sort(sortOrder);
            } else {
                
                // console.log(filterOrder)
                newProds = produtos.sort(sortOrder);
            }
        }
        
       
        
        return newProds
    }


    function filtroGlobal(item) { 
        // if (item.categoria.toLowerCase().includes(filter.toLowerCase())) {
        //     return item;
        // }

        return Object.keys(item).some(
            (key) => { 
                const text = item[key] + '';
                return text
                    .toLowerCase()
                    .indexOf(filter.toLowerCase()) !== -1
            },
        )
    };

    function filtroCategory(item) { 
        if (item.categoria.toLowerCase().includes(filterCategory.toLowerCase())) {
            return item;
        }
    };

    function sortOrder(a, b) {
        switch (filterOrder) {
            case 'a-z': 
                return sortOrderFilter(a.descricao, b.descricao, 'asc');
                break;
            case 'z-a': 
                return sortOrderFilter(a.descricao, b.descricao, 'desc');
                break;
            case 'maior-ponto': 
                return sortOrderFilter(Number(a.pontos), Number(b.pontos), 'desc');
                break;
            case 'menor-ponto': 
                return sortOrderFilter(Number(a.pontos), Number(b.pontos), 'asc');
                break;
            case 'maior-valor': 
                return sortOrderFilter(Number(a.bonificacao), Number(b.bonificacao), 'desc');
                break;
            case 'menor-valor': 
                return sortOrderFilter(Number(a.bonificacao), Number(b.bonificacao), 'asc');
                break;
            case 'fornecedor': 
                return sortOrderFilter(a.fornecedorNome, b.fornecedorNome, 'asc');
                break;
            default:
                return 0;
                break;
        }
    }

    // const sortOrderFilterDesc = (a, b) => {
    //     console.log(a);
    //     console.log(b);
    //     if (a > b) {
    //         console.log('if');
    //         return 1;
    //     } else if (a < b) {
    //         console.log('else if');
    //         return -1;
    //     } else {
    //         console.log('else');
    //         return 0;
    //     }
    // }

    // const sortOrderFilterAsc = (a, b) => {
    //     console.log(a);
    //     console.log(b);
    //     if (a > b) {
    //         return -1;
    //     } else if (a < b) {
    //         return 1;
    //     } else {
    //         return 0;
    //     }
    // }

    function sortOrderFilter(a, b, type) {
        
        if (type == 'asc') {
            if (a > b) {
                console.log('maior')
                return 1;
            } else if (a < b) {
                console.log('menor')
                return -1;
            } else {
                console.log('igual')
                return 0;
            }
        } else {
            if (a > b) {
                return -1;
            } else if (a < b) {
                return 1;
            } else {
                return 0;
            }
        }
        
    }

    const handleChange = (event) => {
        event.persist()
        setFilter(event.target.value);
    }

    const handleClickCategoria = (e, categoria) =>{
        if(categoria == 'todos'){
            setFilteredCategoria('')
            // setFilter(categoria);
            setFilterCategory('');
        } else {
            setFilteredCategoria(e.target.textContent.toLowerCase())
            // setFilter(categoria);
            setFilterCategory(categoria);
        }
        setFilterOrder('');
        setShowDrop(false);
    }

    const handleExtratoPontoClick = () => {
        
        extratoDePontosRef.current.open();
    }

    const handleShowMenu = () =>{
        setShowDrop(!showDrop);
    }

    function atualizarDados() {
        obterProdutos().then(result => {                  
            setProdutos(result.payload.data.results);
        });
        obterClienteResumo().then(result => {
            setPerfil(result.payload.data);
        })
        obterExtrato().then(result => {
            setExtrato(result.payload.data);
        });
        obterSaldo().then(result => {
            const newSession = {
                ...session,
                perfil: {
                    ...session.perfil,
                    pontos: result.payload.data.saldo
                }
            };
            gravarDados(newSession)
            setSession(newSession);
        });
    }

    function handleSelectOrder(type) {
        setFilterOrder(type);

        switch (type) {
            case 'a-z': 
                break;
            case 'z-a': 
                break;
            case 'maior-ponto': 
                
                break;
            case 'menor-ponto': 
                
                break;
            case 'maior-valor': 
                
                break;
            case 'menor-valor': 
                
                break;
            case 'fornecedor': 
                
                break;
            default:

                break;
        }
    }

    function handleCloseDropDown() {
        setShowDrop(false);
    }

    const ListFilters = (mobile) => {
        
        return <ul>                                  
            {!mobile.mobile &&
                <li>Categorias</li>
            }
            <li onClick={(e)=>handleClickCategoria(e, 'todos')}>Todas</li>
            <li onClick={(e)=>handleClickCategoria(e, 'brinquedos e crian')}>Infantil</li>
            <li onClick={(e)=>handleClickCategoria(e, 'casa e constru')}>Construção</li>
            <li onClick={(e)=>handleClickCategoria(e, 'casa e decora')}>Decoração</li>
            <li onClick={(e)=>handleClickCategoria(e, 'combust')}>Combustível e serviços</li>
            <li onClick={(e)=>handleClickCategoria(e, 'cultura e entretenimento')}>Cultura e entretenimento</li>
            <li onClick={(e)=>handleClickCategoria(e, 'cupom exclusivo')}>Cupons exclusivos</li>
            <li onClick={(e)=>handleClickCategoria(e, 'cursos')}>Cursos</li>
            <li onClick={(e)=>handleClickCategoria(e, 'delivery')}>Delivery</li>
            <li onClick={(e)=>handleClickCategoria(e, 'doa')}>Doação</li>
            <li onClick={(e)=>handleClickCategoria(e, 'eletr')}>Eletrônicos</li>
            <li onClick={(e)=>handleClickCategoria(e, 'esporte e lazer')}>Esporte e lazer</li>
            <li onClick={(e)=>handleClickCategoria(e, 'experi')}>Experiências</li>
            <li onClick={(e)=>handleClickCategoria(e, 'games e software')}>Games e software</li>
            <li onClick={(e)=>handleClickCategoria(e, 'hipermercado e varejo')}>Hipermercado</li>
            <li onClick={(e)=>handleClickCategoria(e, 'informati')}>Informática e papelaria</li>
            <li onClick={(e)=>handleClickCategoria(e, 'mobilidade')}>Mobilidade</li>
            <li onClick={(e)=>handleClickCategoria(e, 'moda e acess')}>Moda e acessórios</li>
            <li onClick={(e)=>handleClickCategoria(e, 'culos e acess')}>Óculos e acessórios</li>
            <li onClick={(e)=>handleClickCategoria(e, 'petshop')}>Petshop</li>
            <li onClick={(e)=>handleClickCategoria(e, 'presentes e flores')}>Presentes e flores</li>
            <li onClick={(e)=>handleClickCategoria(e, 'programa de pontos')}>Programa de pontos</li>
            <li onClick={(e)=>handleClickCategoria(e, 'restaurantes e caf')}>Restaurante e cafés</li>
            <li onClick={(e)=>handleClickCategoria(e, 'e beleza')}>Saúde e beleza</li>
            <li onClick={(e)=>handleClickCategoria(e, 'servi')}>Serviços</li>
            <li onClick={(e)=>handleClickCategoria(e, 'varejo virtual')}>Varejo virtual</li>
            <li onClick={(e)=>handleClickCategoria(e, 'viagens e turismo')}>Viagens e turismo</li>
        </ul>
    }


    return (
        <>
        <div className="contaMeusPontos">
            <ExtratoDePontos ref={extratoDePontosRef} saldo={perfil.pontos} pontos={extrato} />
            <Menu simples={false} pesquisa={false} className="custom-menu" isPerfil={true} />
            <div className='divTitulo'>
                <div className='tituloMeuspontos'>
                    <h1>Troque seus pontos</h1>
                </div>
            </div>
        
        
            {/* <div className="dados-conta">
            
                <div className="topo">
                    <div className="topo-item">
                        <ContaPerfil perfil={perfil} />
                        
                    </div>
                    <div className="topo-item">
                        <ContaClassificacao />
                    </div>
                    <div className="topo-item">
                       
                    </div>
                </div>
            </div> */}
            <div className="resgate-seus-pontos">
                
                <CardPopup ref={cardPopup} />

                    <div className='showInMobile mobileItemCard' style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap'}}>
                        
                        <div style={{width: '100%'}}>
                            <div className='container-filtros-mobile'>
                                {/* <div className='grupoBotoes'> */}
                                    <BotaoBordaArredondadoSemTransparenciaMobile titulo="Saldo de pontos:" pontos={perfil.pontos} value={`Saldo de pontos: ${perfil.pontos}`} className="btn-saldo-pontos" onClick={handleExtratoPontoClick} handleSelectOrder={handleSelectOrder} />
                                    <FiltroVoucherMobile handleChange={handleChange} className='filtro-voucher-mobile'  />
                                    
                                
                                {/* </div> */}
                              
                                <div className='listaCategoriaMobile' onClick={handleShowMenu} ref={refDropDownCategory}>
                                    <div className='divBtMenu'>
                                        <a className='btnMenu' ><span>Categorias</span><FontAwesomeIcon icon={showDrop ? faChevronUp : faChevronDown} className='icone-categoria'/></a>
                                    </div>
                                    
                                    {showDrop && (

                                        <ListFilters mobile={true} />
                                    )}
                                </div>

                            </div>
                            <ListaResgatePontosHorizontal pontos={perfil.pontos} className="custom-lista-resgate-pontos" handleChange={handleChange} listaResgatePontos={prodsFiltred} onResgate={atualizarDados} />
                        </div>
                    </div>

                    <div className='hideInMobile divResgates' >
                        <div className='listaCategoria'>
                            <ListFilters mobile={false} />
                        </div>
                        <div className='campoRetornoBusca'>
                            <div className='divInputBusca'>
                                <div className='container-filtros'>
                                    <FiltroVoucherPontos handleChange={handleChange} className="filtro-voucher-custom" handleSelectOrder={handleSelectOrder} />
                                    <BotaoBordaArredondadoSemTransparenciaPontos titulo="Saldo de pontos:" pontos={perfil.pontos} className="conta-perfil-botao-extrato" onClick={handleExtratoPontoClick} />
                                    {/* <LabelBordaArredondado value="Saldo de pontos: " pontos={perfil.pontos} className="conta-saldo-botao" /> */}
                                </div>
                                <div className="breadcrumbMeusPontos">
                                    <a className='filteredCategoriaWhite' href='/'>{'home > '}</a>
                                    <a className='filteredCategoriaWhite' href='/meus-pontos'>{'troque seus pontos '}</a>

                                    {filteredCategoria != '' && (
                                        <>
                                            <span>{'> '}</span>
                                            <span className='filteredCategoria'>{filteredCategoria}</span>
                                        </>
                                    )}
                                    
                                </div>
                                <ListaResgatePontosHorizontal pontos={perfil.pontos} className="custom-lista-resgate-pontos" handleChange={handleChange} listaResgatePontos={prodsFiltred} onResgate={atualizarDados} />
                            </div>
                            
                        </div>
                    </div>
            </div>
        </div>

            
        {/* <Rodape /> */}
        <RodapeConta/>
        </>
    );
}