import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { isLogged, obterAnuncios } from '../../actions/assistamais/api';
import { discoverMovie, getGenresMovieList, getPlayingNowAction, getPopularAction, getEmAltaNowAction } from '../../actions/tmdb';
import Banner1 from '../../componentes/molecules/Banner1';
import CardPopup from '../../componentes/molecules/CardPopup';
import BannerFundo from '../../componentes/organisms/BannerFundo';
import ListaAnunciosHorizontal from '../../componentes/organisms/ListaAnunciosHorizontal';
import ListaFilmesHorizontal from '../../componentes/organisms/ListaFilmesHorizontal';
import ListaProvidersHorizontal from '../../componentes/organisms/ListaProvidersHorizontal';
import Menu from '../../componentes/organisms/Menu';
import Rodape from '../../componentes/organisms/Rodape';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import { SessionContext } from '../../contexts/SessionContext';
import { popularTipo } from '../../utils/titulos';
import './style.css';
import { logout, obterDados } from '../../utils/Session';
import PopupAviso from '../../componentes/organisms/PopupAviso';
import MensagemPadrao, {MessageType} from "../../componentes/atoms/MensagemPadrao";


export default function Home({ videoRef }) {

    const [populares, setPopulares] = useState([]);
    const [novidades, setNovidades] = useState([]);
    const [anuncios, setAnuncios] = useState([]);
    const [carrossel, setCarrossel] = useState([]);
    const [emAlta, setEmAlta] = useState([]);

    const [listaGeneros, setListaGeneros] = useState([]);

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    const cardPopup = useRef(null);

    let history = useHistory();

    const popularGeneros = (results, genres) => {

        for (let r of results) {

            r.genres = [];
            r.genre_ids && r.genre_ids.forEach(id => {
                r.genres.push(genres.find(g => g.id === id))
            });
        }
    }

    const popularLinkDetalhe = (results, mediaType) => {

        for (let r of results) {
            let pathname = '/filme';
            if (mediaType === 'tv' || (r.tipo && r.tipo.toLowerCase() === 'tv')) {
                pathname = 'serie';
            }
            r.linkDetalhe = `${pathname}/?id=${r.id}&type=${r.tipo}`;
        }
    }

    const carregarAnuncios = () => {
        try {
            obterAnuncios().then(result => {
                setAnuncios(result.payload.data.results[0].linha);
                console.log(result.payload.data.results[0].carrossel)
                setCarrossel(result.payload.data.results[0].carrossel);
                
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        async function fetchData() {

            

            // TODO pegar genres da memória
            const { genres } = (await getGenresMovieList()).payload.data; // systemData.genres;//

            getPopularAction().then(result => {
                // TODO criar função para usar em todos
                let { results } = result.payload.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results);

                setPopulares(results)

            });

            getPlayingNowAction().then(result => {
                let { results } = result.payload.data;
                popularGeneros(results, genres);
                popularLinkDetalhe(results);

                setNovidades(results);
            });

            carregarAnuncios();

            getEmAltaNowAction().then(result => {
                let { results } = result.payload.data;
                popularGeneros(results, genres);
                popularLinkDetalhe(results);
                
                setEmAlta(results);
            });

            const resultadoGeneros = [];
/*
            Promise.all(
                genres.slice(0,3).map(g => {

                    return discoverMovie(`&with_genres=${g.id}|`).then(result => {
                        let { results } = result.payload.data;

                        popularGeneros(results, genres);
                        popularLinkDetalhe(results, 'movie');

                        let genero = { id:g.id, name: g.name, filmes: results };

                        resultadoGeneros.push(genero);


                    })
                })
            )
*/          for (let i = 0; i < genres.length; i++) {

                const g = genres[i];
                const result = await discoverMovie(`&watch_region=BR&with_genres=${g.id}|`);

                let { results } = result.payload.data;

                popularGeneros(results, genres);
                popularLinkDetalhe(results);
                
                let genero = { id: g.id, name: g.name, filmes: results };

                resultadoGeneros.push(genero);


            }
            setListaGeneros(resultadoGeneros);

            // const dados = await obterDados();

            // if (dados && dados.user && dados.user.id) {
            //     const isUserLogged = await isLogged(dados.user.id)

            //     console.log(isUserLogged.payload.data)

            //     if (!isUserLogged.payload.data.isLogged) {
            //         logout();
            //         window.location.href = '/';
            //     }
            // }
        };
        fetchData()
        getSessionData();

    }, []);

    const handleClick = (e, data) => {

        const { innerWidth: width, innerHeight: height } = window;

        const x = e.pageX > width / 2 ? e.pageX - 432 + 20 : e.pageX;
        const y = e.clientY > height / 2 ? e.pageY - 481 + 20 : e.pageY;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        const x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        const y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleClickVerMais = (id) => {

        history.push({
            pathname: '/titulosPorGenero/',
            search: `?id=${id}`
        }, {
            some: new Date()
        });

    }

    const handleClickMobile = (id) => {

        history.push({
            pathname: '/titulosPorGenero/',
            search: `?id=${id}`
        }, {
            some: new Date()
        });

    }

    const handleClickPopularVerMais = () => {
        history.push('/titulosPopulares');
    }

    const handleClickLancamentoVerMais = () => {
        history.push('/titulosLancamentos');
    }

    return (
        <div>
            <Menu simples={false} >
                <BannerFundo videoRef={videoRef}>
                    {carrossel.map(c =>
                        <Banner1
                            key={`anuncio-${c.id}`}
                            anuncio={c}
                            labelButton="CONFIRA"
                        />
                    )}

                </BannerFundo>
            </Menu>

            
            <CardPopup ref={cardPopup} />

            <ListaProvidersHorizontal providers={systemData.providers} titulo={"Plataformas"} />
            <ListaAnunciosHorizontal onCheckMonetizado={() => carregarAnuncios()} anuncios={anuncios} key="lista-anuncios" />

            <ListaFilmesHorizontal
                cardPopup={cardPopup}
                filmes={populares}
                titulo={"Populares"}
                onClick={handleClick}
                onMouseOver={handleMouseOver}
                onClickVerMais={() => handleClickPopularVerMais()}
                style={{ marginBottom: 32}}
            />

            <ListaFilmesHorizontal
                cardPopup={cardPopup}
                filmes={novidades}
                titulo={"Lançamentos"}
                onClick={handleClick}
                onMouseOver={handleMouseOver}
                onClickVerMais={() => handleClickLancamentoVerMais()} />

            <ListaFilmesHorizontal
                cardPopup={cardPopup}
                onMouseOver={handleMouseOver}
                filmes={emAlta}
                titulo={"Em alta"}
                onClick={handleClick} />

            {listaGeneros?.map(genero => {
                return (
                    <ListaFilmesHorizontal
                        cardPopup={cardPopup}
                        key={`lista-filmes-genero-${genero.id}`}
                        titulo={genero.name}
                        filmes={genero.filmes}
                        onMouseOver={handleMouseOver}
                        onClickVerMais={() => handleClickVerMais(genero.id)}
                        onClick={handleClick}
                    />
                )
            })}

            <RodapeConta />
        </div>
    );
}


Home.propTypes = {

};

