import React from 'react';
import './style.scss';


export default function BotaoBordaArredondadoSemTransparenciaPontos({ className, onClick, titulo, pontos, value, children, ...rest }) {


    return (
        <div className={`botao-borda-arredondado-pontos ${className}`} onClick={onClick}>

            <div className="botao-borda-arredondado-text-pontos">
                {titulo} <span>{pontos}</span>
            </div>
            {/* <div className="transparencia"></div> */}
            {children}
        </div>
    );
}