import React from 'react';
import './style.scss';


export default function BotaoBordaArredondadoSemTransparencia({ className, classNameText = '', onClick, value, children, ...rest }) {

    return (
        <div className={`botao-borda-arredondado-mobile ${className}`} onClick={onClick}>

            <div className={`botao-borda-arredondado-text ${classNameText}`}>{value}</div>
            {/* <div className="transparencia"></div> */}
            {children}
        </div>
    );
}