import React from 'react';
import './style.scss';


export default function InputArredondado({ className = '', name, error, label, ...rest }) {

    return (
        <div className={`input-retangular ${className}`}>
            {error && error[name] ? <span className="input-error">{error[name]}</span> : label && <span className="input-label">{label}</span>}
            <input {...rest} />
        </div>
    );
}