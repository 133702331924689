import React, { useEffect, useContext, useState } from 'react';
import ProgressoComLegenda from '../../molecules/ProgressoComLegenda';
import { MessageContext } from '../../../contexts/MessageContext';
import { useHistory } from "react-router-dom";


import './style.scss';

import { useForm } from "../../../hooks/useForm";
import { usarCupom } from '../../../actions/assistamais/api';

import BotaoBordaArredondado from '../../atoms/BotaoBordaArredondado';
import InputRetangular from '../../atoms/InputRetangular';
import BotaoBordaArredondadoSemTransparencia from '../../atoms/BotaoBordaArredondadoSemTranparencia';



const ContaSaldo = ({ saldo = 0, className, extratoPontoClick }) => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const marcacao = [500, 750, 1000, 2500, 5000, 10000, 50000, 75000, 100000, 1000000, 10000000, 100000000];
    const [totalVariavel, setTotalVariavel] = useState(500);

    const [showMessage, showError] = useContext(MessageContext);
    let history = useHistory();

    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
    } = useForm({ // the hook we are going to create
        validations: { // all our validation rules go here
            codigo: {
                required: {
                    value: true,
                    message: 'Preencha o código do cupom',
                },
            },            
        },
        onSubmit: () => {
            const body = {
                codigo: data.codigo
            };

            usarCupom(body).then(result => {
                if (result.payload.status * 1 < 200 || result.payload.status * 1 > 299) {
                    showError(result.payload.data.message)
                } else {
                    showMessage('Cupom adicionado com sucesso');
                    setTimeout(() => window.location.href = '/minha-conta', 2000);
                }
            });
        },
        initialValues: { // used to initialize the data
            codigo: '',            
        },
    });

    useEffect(() => {
        try {
            setTotalVariavel(marcacao.filter(m => m > saldo)[0])
        } catch {
            setTotalVariavel(500)
        }
    }, [marcacao, saldo])

    function clickResgatar() {
        history.push('/meus-pontos')
    }

    return (
        <div className={"conta-saldo"}>
            <div className="conta-saldo-titulo"><span>Programa Use Mais Pontos:</span></div>
            <ProgressoComLegenda progresso={saldo} total={totalVariavel} clickExtrato={extratoPontoClick} clickResgatar={clickResgatar} />
            <div className="conta-saldo-cupom">
                <form onSubmit={handleSubmit}>
                    <div className="campoCupons">

                        <InputRetangular type="text" minLength="3" className="campoCupom-input" placeholder="DIGITE SEU CÓDIGO" label="Cupom de pontos:" value={data['codigo']} onChange={handleChange('codigo')} error={errors} name="codigo" />

                        <BotaoBordaArredondadoSemTransparencia value="ok" className="conta-saldo-botao" classNameText="conta-saldo-botao-text" onClick={handleSubmit} />
                        
                    </div>
                </form>
            </div>
        </div>
    )
};

export default ContaSaldo;