import { useState } from 'react';
import BotaoArredondado from '../../atoms/BotaoArredondado';
import ThumbQuadrada from '../../atoms/ThumbQuadrada';
import './style.scss';

const CardResgatePonto = ({ pontos = 0, img, textoPremio = '', onClick, className = '', mostrarBotao = true, clickButton=true }) => {

    return (

        <div className={`card-resgate-pontos ${className}`}>
            <div className="card-resgate-total-pontos">
                <span>{pontos} Pontos</span>
            </div>

            <div className="card-resgate-pontos-body">
                <div className="card-thumb-custom-resgate">
                    <ThumbQuadrada src={img} className="thumb-custom-resgate"></ThumbQuadrada>
                </div>
                <div className="card-resgate-premio">
                    <span>
                        {textoPremio}
                    </span>
                </div>
            </div>

            {clickButton ? 
                <BotaoArredondado ativo={mostrarBotao} value="RESGATAR" disabledValue="RESGATAR" disabledTitle ="Você ainda não tem os pontos suficientes para trocar por este item. Continue navegando na plataforma e pontuando. É fácil! É rápido!" className="botao-resgate-custom" onClick={onClick} />
            : 
                <BotaoArredondado ativo={false} value="asdsadasd" disabledValue="RESGATANDO..." className="botao-resgate-custom"  />
            }


        </div>

    )
};

export default CardResgatePonto;