import { gapi } from 'gapi-script';
import React, { useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import './style.scss';


export default function BotaoGoogleLogin({ ...rest }) {

    const clientId = '175857597463-qsf2pu0o23camne76motu6r3rtf6m9sm.apps.googleusercontent.com';

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    }, []);

    return (
       <GoogleLogin
            clientId={clientId}
            {...rest}
        />
    );
}