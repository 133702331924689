import { useEffect, useRef } from "react";
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route } from "react-router-dom";
import VideoPopup from "./componentes/atoms/VideoPopup";
import { MessageApiProvider } from "./contexts/MessageContext";
import { ApiProvider } from "./contexts/SessionContext";
import Ajuda from "./paginas/Ajuda";
import Anuncie from "./paginas/Anuncie";
import BlogNoticia from "./paginas/BlogNoticia";
import ComoFunciona from './paginas/ComoFunciona';
import ConfirmacaoEmail from "./paginas/ConfirmacaoEmail";
import ContaUsuario from "./paginas/ContaUsuario";
import ConteudoPost from "./paginas/ConteudoPost";
import DetalheDaSerie from './paginas/DetalheDaSerie';
import DetalheDoFilme from './paginas/DetalheDoFilme';
import EditarPerfil from "./paginas/EditarPerfil";
import Home from './paginas/Home';
import ListasPersonalizadas from "./paginas/ListasPersonalizadas";
import ListaTitulosFavoritos from "./paginas/ListaTitulosFavoritos";
import ListaTitulosLancamentos from "./paginas/ListaTitulosLancamentos";
import ListaTitulosListaPersonalizada from "./paginas/ListaTitulosListaPersonalizada";
import ListaTitulosPopulares from "./paginas/ListaTitulosPopulares";
import ListaTituloPorPlataforma from "./paginas/ListaTitulosPorPlataforma";
import LoginAutomatico from "./paginas/LoginAutomatico";
import Pesquisa from './paginas/Pesquisa';
import ResetSenha from "./paginas/ResetSenha";
import TituloPorGenero from "./paginas/TitulosPorGenero";
import TituloPorGeneroGenero from "./paginas/TitulosPorGeneroEGenero";
import TituloPorPlataformaGenero from "./paginas/TitulosPorPlataformaEGenero";
import MeusPontos from "./paginas/MeusPontos";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-222641016-1'); // G-3YQ7TH17EW


const tagManagerArgs = {
  gtmId: 'GTM-PS26QK2'
}
TagManager.initialize(tagManagerArgs)



function App() {
  const RotaTemaPadrao = ({ component: Component, titulo, completo, ...rest }) => {

    const videoRef = useRef()


    return (
      <Route
        {...rest}
        render={props => (
          <>
            <VideoPopup ref={videoRef} />
            <Component {...props} videoRef={videoRef} />

          </>
        )}
      />
    );
  }
  window.dataLayer.push({
    event: 'pageview'
  });

  ReactGA.pageview(window.location.pathname + window.location.search);

  const meta = {
    title: 'Some Meta Title',
    description: 'I am a description, and I can create multiple tags',
    canonical: 'http://example.com/path/to/page',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'react,meta,document,html,tags'
        }
    }
  }
  

  return (
    <ApiProvider>
      <MessageApiProvider>
        <Router>
          <RotaTemaPadrao exact path="/" component={Home} />
          <RotaTemaPadrao exact path="/ajuda" component={Ajuda} forceRefresh={true} />
          <RotaTemaPadrao exact path="/anuncie" component={Anuncie} />
          <RotaTemaPadrao exact path="/comofunciona" component={ComoFunciona} forceRefresh={true} />
          <RotaTemaPadrao exact path="/conteudoPost" component={ConteudoPost} forceRefresh={true} />
          <RotaTemaPadrao exact path="/editarperfil" component={EditarPerfil} forceRefresh={true} />
          <RotaTemaPadrao exact path="/email-verification" component={ConfirmacaoEmail} forceRefresh={true} />
          <RotaTemaPadrao exact path="/favoritos" component={ListaTitulosFavoritos} forceRefresh={true} />
          <RotaTemaPadrao exact path="/filme" component={DetalheDoFilme} forceRefresh={true} />
          <RotaTemaPadrao exact path="/listas" component={ListasPersonalizadas} forceRefresh={true} />
          <RotaTemaPadrao exact path="/minha-conta" component={ContaUsuario} forceRefresh={true} />
          <RotaTemaPadrao exact path="/noticias" component={BlogNoticia} forceRefresh={true} />
          <RotaTemaPadrao exact path="/pesquisa" component={Pesquisa} forceRefresh={true} />
          <RotaTemaPadrao exact path="/reset-password" component={ResetSenha} forceRefresh={true} />
          <RotaTemaPadrao exact path="/serie" component={DetalheDaSerie} forceRefresh={true} />
          <RotaTemaPadrao exact path="/titulos-genero-genero" component={TituloPorGeneroGenero} forceRefresh={true} />
          <RotaTemaPadrao exact path="/titulos-lista-personalizada" component={ListaTitulosListaPersonalizada} forceRefresh={true} />
          <RotaTemaPadrao exact path="/titulos-plataforma-genero" component={TituloPorPlataformaGenero} forceRefresh={true} />
          <RotaTemaPadrao exact path="/titulosLancamentos" component={ListaTitulosLancamentos} forceRefresh={true} />
          <RotaTemaPadrao exact path="/titulosPopulares" component={ListaTitulosPopulares} forceRefresh={true} />
          <RotaTemaPadrao exact path="/titulosPorGenero" component={TituloPorGenero} forceRefresh={true} />
          <RotaTemaPadrao exact path="/titulosPorPlataforma" component={ListaTituloPorPlataforma} forceRefresh={true} />
          <RotaTemaPadrao exact path="/transparent-login" component={LoginAutomatico} forceRefresh={true} />
          <RotaTemaPadrao exact path="/meus-pontos" component={MeusPontos} forceRefresh={true} />

        </Router>
      </MessageApiProvider>
    </ApiProvider>
  );
}

export default App;
