import { useContext, useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from 'react-responsive';
import { resgatarDePontos, enviarSms, validarSms } from '../../../actions/assistamais/api';
import verified from '../../../assets/img/verified.png';
import { MessageContext } from '../../../contexts/MessageContext';
import { obterDados } from '../../../utils/Session';
import BotaoArredondado from '../../atoms/BotaoArredondado';
import DialogPopup from '../../atoms/DialogPopup';
import CardResgatePonto from '../../molecules/CardResgatePonto';
import Popup from '../../molecules/Popup';
import PopupSms from '../PopupSms';
import './style.scss';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1367 },
        items: 4,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1366, min: 1024 },
        items: 4,
        slidesToSlide: 4
    },
    tablet: {
        breakpoint: { max: 991, min: 464 },
        items: 2,
        slidesToSlide: 2,
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        slidesToSlide: 1,
        items: 1,
        partialVisibilityGutter: 30
    }
};

const ListaResgatePontosHorizontal = ({ onClick, listaResgatePontos = [], className = '', onResgate, pontos, handleChange }) => {
    const dialogRef = useRef();

    // const [produtos, setProdutos] = useState(listaResgatePontos);
    const [produtoSelecionado, setProdutoSelecinado] = useState({});
    const [showMessage, showError] = useContext(MessageContext);
    const [button, setButton] = useState(true);
    const [showSms, setShowSms] = useState(false);
    const [validaSms, setValidaSms] = useState(false);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const dados = obterDados();

    const [showAviso, setShowAviso] = useState(false);

    // useEffect(() => {

    //     let newArray = listaResgatePontos
    //     setProdutos(newArray)
    // }, [listaResgatePontos]);

    // useEffect(() => {
    //     console.log(produtos);
    // }, [])

    const handleClick = (produto) => {        
        setButton(false);
        setProdutoSelecinado(produto);
        dialogRef.current.show()
    }

    const handleCancel = () => {
        setButton(true);
    }

    const handleResgateConfirm = async() => {

        if(!validaSms){
            enviarSms({cpf: dados.cpf, telefone: dados.celular }).then(result => {
                // showMessage(result.payload.data.message);
                setShowSms(true);
                // if (result.payload.status * 1 < 200 || result.payload.status * 1 > 299) {
                // } else {
                // }
            });
        }else{

            resgatarDePontos(produtoSelecionado.codigo, produtoSelecionado.categoria).then(resultado => {
                if (Math.trunc(resultado.payload.status / 100) === 2) { // 2xx => Resultado ok
                    // showMessage('Produto resgatado com sucesso');
                    setShowAviso(true);
                    if (onResgate) {
                        onResgate();
                    } 
                } else {
                    showError(resultado.payload.data.message);
                }
            })
        }
    }

    const ordenarProdutos = (a, b) => {
        return a.pontos > b.pontos ? 1 : a.pontos < b.pontos ? -1 : 0;
    }

    const handleFecharAvisoClick = () => {
        setShowAviso(false);
        window.location.reload();
    }

    const closePupupSms = () => {

        // if(!validaSms){
        //     showError("Por favor realize a validação do token.")
        // }else{
            setShowSms(false)
        // }

        handleCancel()
        
    }

    const handleValidarClick = (codigo) => {

        validarSms({codigo: codigo, telefone: dados.celular }).then(result => {
            if (result.payload.status * 1 < 200 || result.payload.status * 1 > 299) {
                showError(result.payload.data.message)
            } else {
                setShowSms(false);
                setValidaSms(true);
                resgatarDePontos(produtoSelecionado.codigo, produtoSelecionado.categoria).then(resultado => {
                    if (Math.trunc(resultado.payload.status / 100) === 2) { // 2xx => Resultado ok
                        // showMessage('Produto resgatado com sucesso');
                        setShowAviso(true);
                        if (onResgate) {
                            onResgate();
                        } 
                    } else {
                        showError(resultado.payload.data.message);
                    }
                })
            }
        }); 
    }
    console.log(listaResgatePontos)
    return (

        <>
        {showSms &&
            <PopupSms titulo="CÓDIGO" mensagem={`Para realizar o resgate enviamos um código via SMS para o telefone que esta cadastrado na sua conta do Assista Mais.`} onClose={closePupupSms} validarClick={handleValidarClick} className={'popup-resgate-custom'} />
        }

        <div className={`container-lista-resgate-pontos-horizontal ${className}`}>
            <DialogPopup 
                ref={dialogRef} 
                allowClose={true} 
                title="Muito bem!" 
                subtitle="Deseja resgatar seus pontos agora?" 
                onConfirm={handleResgateConfirm} 
                onCancel={handleCancel} 
                popupPontos={true} 
                className="popup-resgate-agora-custom" 
                confirmLabel='SIM'
            />

            {showAviso && <Popup onClose={handleFecharAvisoClick}>
                <div className="popup-resgate-concluido">
                    <img src={verified} alt="" />
                    <div className='popup-resgate-concluido-titulo'>Parabéns!</div>
                    <div className='popup-resgate-concluido-mensagem'>Você acaba de adquirir um item especial. Dê uma conferida no seu e-mail e siga as instruções para o resgate dos seus pontos.</div>
                    <BotaoArredondado value="OK" className='popup-resgate-custom-button' onClick={handleFecharAvisoClick} />
                </div>

            </Popup>}

            {listaResgatePontos.length > 0 ?
                (listaResgatePontos.map(produto =>
                    <CardResgatePonto key={`item-resgate-${produto.codigo}`} img={produto.imagemCapa} className="custom-card-resgate" textoPremio={produto.descricao} pontos={produto.pontos} onClick={() => handleClick(produto)} mostrarBotao={pontos >= produto.pontos} clickButton={button} />
                ))
            :
                (<p className='text'>Nenhum produto encontrado para essa categoria ou filtro.</p>)
            }


            {/* <Carousel responsive={responsive}
                swipeable={true}
                draggable={true}
                infinite={true}
                // centerMode={!isTabletOrMobile}
                // partialVisible={isTabletOrMobile}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed= {120000}
                removeArrowOnDeviceType={["tablet", "mobile"]}
            //deviceType={this.props.deviceType||''}
            >
                {isTabletOrMobile ?
                
                produtos.sort(ordenarProdutos).slice(0, 40).map(produto =>
                    <CardResgatePonto key={`item-resgate-${produto.codigo}`} img={produto.imagemCapa} className="custom-card-resgate" textoPremio={produto.descricao} pontos={produto.pontos} onClick={() => handleClick(produto)} mostrarBotao={pontos >= produto.pontos} clickButton={button} />
                ) :
                produtos.sort(ordenarProdutos).map(produto =>
                    <CardResgatePonto key={`item-resgate-${produto.codigo}`} img={produto.imagemCapa} className="custom-card-resgate" textoPremio={produto.descricao} pontos={produto.pontos} onClick={() => handleClick(produto)} mostrarBotao={pontos >= produto.pontos} clickButton={button} />
                )
                }
            </Carousel> */}


        </div>
        </>
    )
};

export default ListaResgatePontosHorizontal;