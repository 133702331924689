import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import TrocarSenha from '../../componentes/organisms/TrocarSenha';
import Home from '../Home';
import './style.scss';

export default function ResetSenha() {

    const [token, setToken] = useState('');

    const trocarSenhaRef = useRef();

    useEffect(() => {
        const params = queryString.parse(window.location.search);
        setToken(params.token);
        trocarSenhaRef.current.open();
    }, [])

    return (
        <div className="reset-senha">


            <Home />
            <TrocarSenha ref={trocarSenhaRef} resetSenha={true} token={token} />

        </div>
    );
}



