import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import './style.scss';


export default function BotaoArredondadoOrdernar({ className = '', handleSelectOrder, value, ativo = true, disabledTitle = '', disabledValue = '', ...rest }) {

    const [showOrdened, setShowOrdened] = useState(false);

    const refDropDown = useDetectClickOutside({ onTriggered: handleCloseDropDown });

    function handleOrder() {
        setShowOrdened(!showOrdened);
    }

    function handleCloseDropDown() {
        setShowOrdened(false);
    }

    function handleSelectOrdener(value) {
        handleSelectOrder(value)
        setShowOrdened(false)
    }

    return (
        <button className={`botao-arredondado-ordenar ${className} ${ativo ? '' : 'botao-inativo'}`} {...rest} disabled={!ativo} title={!ativo ? disabledTitle : ''} onClick={handleOrder} ref={refDropDown} >
            <div className="text">{!ativo && disabledValue ? disabledValue : value}</div>
            <span>
                <FontAwesomeIcon icon={!showOrdened ? faChevronDown : faChevronUp} size="lg" className='icon-display' />
            </span>

            <div className={`div-list-order lista-orders ${showOrdened ? 'show': ''}`}>
                <ul>
                    <li onClick={() => handleSelectOrdener('a-z')}>A - Z</li>
                    <li onClick={() => handleSelectOrdener('z-a')}>Z - A</li>

                    <li onClick={() => handleSelectOrdener('maior-ponto')}>Maior ponto</li>
                    <li onClick={() => handleSelectOrdener('menor-ponto')}>Menor ponto</li>

                    <li onClick={() => handleSelectOrdener('maior-valor')}>Maior valor</li>
                    <li onClick={() => handleSelectOrdener('menor-valor')}>Menor valor</li>
                
                    <li onClick={() => handleSelectOrdener('fornecedor')}>Parceiros</li>

                </ul>
            </div>
        </button>
    );
}