import BotaoArredondadoOrdernar from '../../atoms/BotaoArredondadoOrdernar';
import InputBuscaResgates from '../../atoms/InputBuscaResgates';
import './style.scss';

const FiltroVoucherPontos = ({ placeholder, handleChange, className = '', handleSelectOrder}) => {

    return (

        <div className={`filtro-voucher ${className}`}>
            <InputBuscaResgates className="input-resgate" placeholder={placeholder} handleChange={handleChange} />                
            <BotaoArredondadoOrdernar ativo={true} value="Ordenar por" className="botao-resgate-custom" handleSelectOrder={handleSelectOrder} />
        </div>

    )
};

export default FiltroVoucherPontos;