import { useContext, useEffect, useRef, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardAvatar from '../../atoms/CardAvatar';
import './style.scss';
import CardResultadoBuscaAvatar from '../../atoms/CardResultadoBuscaAvatar';
import { gravarDados, obterDados } from '../../../utils/Session';
import { MessageContext } from '../../../contexts/MessageContext';
import { obterFotoPerfil, obterAvataresPerfil, editarFotoPerfil } from '../../../actions/assistamais/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { SessionContext } from '../../../contexts/SessionContext';
import config from '../../../config/default';


// import avatar1 from '../../../avatar/img1.jpg';
// import avatar2 from '../../../avatar/img2.jpg';
// import avatar3 from '../../../avatar/img5.jpg';
// import avatar4 from '../../../avatar/img4.jpg';
// import avatar5 from '../../../avatar/img3.jpg';






const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1461},
        items: 4,
        slidesToSlide: 3
    },
    desktop: {
        breakpoint: { max: 1460, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        slidesToSlide: 2,
        items: 2
    }
};
// const avatares = [{image:avatar1,id:1}, {image:avatar2,id:2} ,{image:avatar3,id:3} ,{image:avatar4,id:4},{image:avatar5,id:5}];

const ListaAvatarHorizontal = ({titulo, notMouseOver = false, onClick, onClickVerMais, onMouseOver, onMouseLeave, cardPopup })=> {

    

    const dados = obterDados();
    const [avatares, setAvatares] = useState([]) 
    const [listaFilmes, setListaFilmes] = useState(avatares);
    const [, , showMessageFromResultRequest] = useContext(MessageContext);
    const [foto, setFoto] = useState();
    const [session, setSession, logado] = useContext(SessionContext);

    useEffect(() => {
        async function fetchData() {
            obterAvataresPerfil()
                .then(
                    result=>
                    {
                        setAvatares(result.payload.data);
                    });
        }
        fetchData()
    }, []);


    const handleChangeImage = async (e) => {
        if (e) {
            if (showMessageFromResultRequest(editarFotoPerfil(e), "Foto atualizada com sucesso")) {
                const urlFoto = `${config.staticURL}avatar/${e.name}`;
                const dadosLocalStorage = obterDados();

                const newDadosLocalStorage = {
                    ...dadosLocalStorage,
                    foto: {
                        mimetype: e.type,
                        originalname: e.name
                    },
                    perfil: {
                        ...dadosLocalStorage.perfil,
                        foto: urlFoto
                    }
                };

                gravarDados(newDadosLocalStorage);

                setSession({
                    ...session,
                    perfil: {
                        ...session.perfil,
                        foto: urlFoto,
                    }
                })
            }
        }
    }
    const handleMouseOver = (e) => {
        console.log(e.target);
    }

    // const urltoFile = (url, filename, mimeType)=>{
    //     return (fetch(url)
    //         .then(function(res){return res.arrayBuffer();})
    //         .then(function(buf){return new File([buf], filename,{type:mimeType});})
    //     );
    // }

    // const dataURLtoFile=(dataurl, filename)=> {
    //     var arr = dataurl.split(','),
    //         mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[1]), 
    //         n = bstr.length, 
    //         u8arr = new Uint8Array(n);       
    //     while(n--){
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }       
    //     return new File([u8arr], filename, {type:mime});
    // }

    const handleChangeAvatar = async(e) => {
        handleChangeImage({
            name: e.target.name,
            type: e.target.dataset.mimetype
        }); 

    }

    return !avatares || avatares.length === 0 ? <></> : (

        <div className="containerListaAvatarHorizontal">
            <div className="titulo">
                <div className="Text-Style-2">{titulo}</div>
                {onClickVerMais ?
                    <div className="Text-Style-3 titulo-ver-mais" onClick={onClickVerMais}>Ver todos</div>
                    :
                    <div className="Text-Style-3 titulo-ver-mais" ></div>
                }
            </div>

            <Carousel 
                responsive={responsive}
                swipeable={true}
                draggable={true}
                // infinite={true}
                // centerMode={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                // autoPlaySpeed={120000}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                arrows= {true}  
            >
                {avatares.map(f=>
                    // <div>
                        <div key={f.id} className='divImagem' onClick={handleChangeAvatar}>
                            <img src={f.image} name={f.name} onMouseOver={handleMouseOver} data-mimetype={f.type} />
                        </div>
                    // </div>    
                )}
            </Carousel>

        </div>
    )
       
};

export default ListaAvatarHorizontal;