import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getFavoritos, obterClienteResumo, obterExtrato, obterProdutos, obterFiltros } from '../../actions/assistamais/api';
import CardPopup from '../../componentes/molecules/CardPopup';
import FiltroVoucher from '../../componentes/molecules/FiltroVoucher';
import ContaPerfil from '../../componentes/organisms/ContaPerfil';
import ContaSaldo from '../../componentes/organisms/ContaSaldo';
import ExtratoDePontos from '../../componentes/organisms/ExtratoDePontos';
import ListaResgatePontosHorizontal from '../../componentes/organisms/ListaResgatePontosHorizontal';
import Menu from '../../componentes/organisms/Menu';
import RodapeConta from '../../componentes/organisms/RodapeConta';
import './style.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import BotaoBordaArredondado from '../../componentes/atoms/BotaoBordaArredondado';
import LabelBordaArredondado from '../../componentes/atoms/LabelBordaArredondado';
import ListaFilmesHorizontalFavoritos from '../../componentes/organisms/ListaFilmesHorizontalFavoritos';
import { SessionContext } from '../../contexts/SessionContext';


export default function ContaUsuario() {
    const [favoritos, setFavoritos] = useState([]);
    const [perfil, setPerfil] = useState([]);
    const [filtros, setFiltros] = useState({categorias: [], pontos: []});
    const [produtos, setProdutos] = useState([]);
    const [extrato, setExtrato] = useState();
    const [filter, setFilter] = useState('');

    const extratoDePontosRef = useRef();
    const cardPopup = useRef(null);

    let history = useHistory();

    const [session, , , systemData, getSessionData] = useContext(SessionContext);

    useEffect(() => {
        getSessionData();
    },[]);

    useEffect(() => {
        async function fetchData() {

            //            const { genres } = (await getGenresMovieList()).payload.data;

            getFavoritos().then(result => {
                // TODO criar função para usar em todos

                if (Math.trunc(result.payload.status / 100) !== 2) return;


                let { data } = result.payload;

                setFavoritos(data.map(f => {
                    let pathname = '/filme';
                    if (f.tipo.toLowerCase() === 'tv') {
                        pathname = 'serie';
                    }
                    const linkDetalhe = `${pathname}/?id=${f.tituloId}&type=${f.tipo}`;
                    const { genres, backdrop_path, poster_path, title, overview } = f.data

                    return {
                        ...f,
                        genres,
                        backdrop_path,
                        poster_path,
                        title,
                        overview,
                        linkDetalhe: linkDetalhe
                    }
                }).reverse());

            });

            obterClienteResumo().then(result => {
                setPerfil(result.payload.data);
            })

            obterProdutos().then(result => {
                setProdutos(result.payload.data.results);
            });

            obterFiltros().then(result => {
                setFiltros({
                    pontos: result.payload.data.pontos, 
                    categorias: result.payload.data.categorias
                });
            });           

            obterExtrato().then(result => {
                setExtrato(result.payload.data);
            });
        }
        fetchData()
    }, []);

    const handleExtratoPontoClick = () => {
        extratoDePontosRef.current.open();
    }

    const handleMouseOver = (e, data, { top, left, width: cardWidth, height: cardHeight }) => {
        const { scrollY, scrollX } = window;

        const width = document.body.clientWidth;
        const multiplicador = width > 1366 ? 1 : 0.71;

        let x = scrollX + left + cardWidth / 2 - (432 * multiplicador) / 2;
        let y = scrollY + top + cardHeight / 2 - (481 * multiplicador) / 2;

        if (x < 0) x = 0;
        if (y < 0) y = 0;
        cardPopup.current.setPosition(x, y);
        cardPopup.current.setData(data);
        cardPopup.current.toggle();
    }

    const handleClickFavoritos = () => {

        history.push({
            pathname: '/favoritos/'
        }, {
            some: new Date()
        });

    }

    const atualizaVoucher = async (c, p) => {
        let prod = await obterProdutos(c, p);
        setProdutos(prod.payload.data.results);
    }

    const handleChange = (event) => {
        event.persist()
        setFilter(event.target.value);
    }

    const filtroGlobal = item =>
        Object.keys(item).some(
            (key) => { 
                const text = item[key] + '';
                return text
                    .toLowerCase()
                    .indexOf(filter.toLowerCase()) !== -1
            },
        );

    return (
        <>
        <div className="conta-usuario">
            <ExtratoDePontos ref={extratoDePontosRef} saldo={perfil.pontos} pontos={extrato} />
            <Menu simples={false} pesquisa={false} className="custom-menu" isPerfil={true} />
            <div className="dados-conta">
                <div className="topo">
                    <div className="topo-item2">
                        <ContaPerfil perfil={perfil} />
                       
                    </div>
                    <div className="Line-2 "></div>
                    <div className="topo-item">
                        <ContaSaldo saldo={perfil.pontos} extratoPontoClick={handleExtratoPontoClick} />
                    </div>
                </div>
            </div>

            <div className="Line-3 "></div>
            <ListaFilmesHorizontalFavoritos cardPopup={cardPopup} filmes={favoritos} titulo={"Lista de Favoritos"} onMouseOver={handleMouseOver}  />
            </div>
                        
            {/* <ListaFilmesHorizontal cardPopup={cardPopup} filmes={favoritos} titulo={"Minha lista"} onMouseOver={handleMouseOver} onClickVerMais={handleClickFavoritos} /> */}

            <RodapeConta/>
        </>
    );
}



