import React from 'react';
import './style.scss';
import BotaoArredondadoMobile from '../../atoms/BotaoArredondadoMobile';
import BotaoArredondadoOrdernar from '../BotaoArredondadoOrdernar';


export default function BotaoBordaArredondadoSemTransparenciaMobile({ className, onClick, titulo, pontos, value, children, handleSelectOrder, ...rest }) {

    let arrayValue = value.split(':');
    
    return (
        <div className='saldoOrdenar'>
            <div className={`botao-borda-arredondadoMobile ${className}`} onClick={onClick}>

            <div className="botao-borda-arredondado-text">
                {titulo} <span>{pontos}</span>
            </div>
            {/* <div className="transparencia"></div> */}
            {children}
            </div>
            <BotaoArredondadoOrdernar ativo={true} value="Ordenar por" className="botao-resgate-custom" handleSelectOrder={handleSelectOrder} />
        </div>
        
    );
}