import BotaoBordaArredondado from '../../atoms/BotaoBordaArredondado';
import Progresso from '../../atoms/Progresso';
import './style.scss';
import BotaoBordaArredondadoSemTransparencia from '../../atoms/BotaoBordaArredondadoSemTranparencia';

const ProgressoComLegenda = ({ progresso, total, clickExtrato, clickResgatar }) => {
    return (
        <div className="progresso-com-legenda">         
            <div className='divBotoesGift'>    
                <div className='boxSaldo'>
                    <span>Saldo de pontos:</span> <span className='spanColor'>{progresso}</span>
                </div>
                <div className='campoBotoes'>
                    {/* <div> */}
                        <BotaoBordaArredondadoSemTransparencia value="RESGATAR GIFT CARD" className="conta-perfil-botao-gift" onClick={clickResgatar} />
                    {/* </div> */}

                    {/* <div> */}
                        <BotaoBordaArredondadoSemTransparencia value="EXTRATO DE PONTOS" className="conta-perfil-botao-pontos" onClick={clickExtrato} />
                    {/* </div> */}
                </div>
            </div>
            
            


        </div>
    )
};

export default ProgressoComLegenda;