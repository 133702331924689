import React, { useImperativeHandle, useState } from 'react';
import Popup from '../../molecules/Popup';
import './style.scss';


export const ExtratoDePontos = ({ pontos = [], saldo }, ref) => {

    const [show, setShow] = useState(false);


    useImperativeHandle(ref, () => ({
        open: () => setShow(true),
        close: () => close(),
        toggle: () => {
            return setShow(!show);
        }
    }));

    const close = () => {
        setShow(false);
    }

    return show && (
        <Popup onClose={(e) => setShow(false)} type="light">

            <div className="extrato-ponto">
                <div className="extrato-header">
                    <div className="header-title">Extrato de pontos </div> <div className="extrato-saldo"> SALDO: <b>{saldo} pts</b> </div>
                </div>
                <div className="table-extrato-ponto">
                    <div className="table-head">
                        <div className="col1">Data(Hora)</div>
                        <div className="col2">Evento</div>
                        <div className="col3">Crédito/Débito</div>
                        <div className="col4">Saldo</div>
                    </div>
                    <div className="table-body">
                        {pontos.map(p => (
                            <div className="row" key={`extrato-ponto-${p.data}`}>
                                <div className="col1">{p.data}</div>
                                <div className="col2 evento-mobile">{p.evento}</div>
                                <div className="col3 col3-valor">{p.pontos}</div>
                                <div className="col4">{p.saldo}</div>
                            </div>
                        ))}
                    </div>
                </div>


            </div>

        </Popup >
    )
};

export default React.forwardRef(ExtratoDePontos);